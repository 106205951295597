import {useState } from "react";
import Box from "@mui/material/Box";
import Card from "@mui/material/Card";
import Typography from "@mui/material/Typography";
import Stack from "@mui/material/Stack";
import Divider from "@mui/material/Divider";
import TextField from "@mui/material/TextField";
import { currencyFormat } from "util/util";


import { FormControl, InputLabel, MenuItem, Select, Skeleton } from "@mui/material";
import { addCouponAsync, adjustDeliveryAsync, removeCouponAsync } from "store/basketSlice";
import { useAppDispatch, useAppSelector } from "store/configureStore";
import { toast } from "react-toastify";
import { LoadingButton } from "@mui/lab";

const CartSummary = (): JSX.Element => {
  const dispatch = useAppDispatch();

  const { status, basket } = useAppSelector((state) => state.basket);

  const [couponCode, setCouponCode] = useState('');
  const [loadingCoupon, setLoadingCoupon] = useState(false);


//   useEffect(() => {
//     dispatch(fetchBasketAsync());
//   }, [dispatch]);

  async function addCoupon(){
    try {

      if (couponCode === ""){
        toast.warning("Ongeldige koepon/geskenkbewys kode. Probeer asb. weer.")
        return;
      }

      setLoadingCoupon(true);

      await dispatch(addCouponAsync({code: couponCode}));
      

      toast.success('Koepon / Geskenkbewys suksesvol bygevoeg');
      setCouponCode('');

    }
    catch(error){
      toast.error(error);
    }
    finally {
      setLoadingCoupon(false);
    }
   
  }

  async function adjustDelivery(deliveryOption: string){
    try {

      await dispatch(adjustDeliveryAsync({deliveryOption: deliveryOption}));

      toast.success('Aflewering opsie suksesvol verander');

    }
    catch(error){
      toast.error(error);
    }
   
  }

  async function removeCoupon(id: number){
    try {
      await dispatch(removeCouponAsync({id: id}));
      toast.success('Koepon / Geskenkbewys suksesvol verwyder');
    }
    catch(error){
      toast.error(error);
    }
  }

  if (status.includes("pending")) {
    return (
        <Card
        elevation={0}
        sx={{
          bgcolor: "alternate.main",
          padding: { xs: 2, sm: 4 },
        }}
      >
      <Skeleton variant="rectangular" height={520} />
          </Card>   
    );
  }


  return (
   
              <Card
                elevation={0}
                sx={{
                  bgcolor: "alternate.main",
                  paddingTop: { xs: 2, sm: 4 },
                  paddingX: { xs: 2, sm: 4 },
                  paddingBottom: 0
                }}
              >
                <Box>
                  <Box
                    //component={"form"}
                    //noValidate
                    //autoComplete="off"
                    sx={{
                      marginY: 2,
                      "& .MuiInputBase-input.MuiOutlinedInput-input": {
                        bgcolor: "background.paper",
                      },
                    }}
                  >
                    <Box sx={{paddingBottom: 2}}>
                    <FormControl fullWidth>
        <InputLabel>Aflewering / Optel</InputLabel>
        <Select
          label="Aflewering Opsie"
          variant="outlined"
          name={"deliveryOption"}
          value={basket.deliveryOption ?? 'Aflewering'}
          onChange={(event) => adjustDelivery(event.target.value)}
          defaultValue={"Aflewering"}
          //error={errors?.deliveryOption != null}
        >
          <MenuItem key={1} value={"Aflewering"}>
            {"Aflewering"}
          </MenuItem>
          {/* <MenuItem key={2} value={"Optel / Eie Koerier - Hartenbos"}>
            {"Optel / Eie Koerier - Hartenbos"}
          </MenuItem> */}
          {/* <MenuItem key={2} value={"Optel / Eie Koerier - Potchefstroom"}>
            {"Optel / Eie Koerier - Potchefstroom"}
          </MenuItem> */}
          {/* <MenuItem key={2} disabled value={"Eie Koerier"}>
            {"Eie Koerier (Bevestig Adres)"}
          </MenuItem> */}
          {/* <MenuItem key={} disabled value={"Optel / Eie Koerier - Potchefstroom (Winkel)"}>
            {"Optel (Potchefstroom Winkel) - Beskikbaar to einde Julie"}
          </MenuItem> */}
           <MenuItem key={2} value={"Eie Koerier - Hartenbos (Fabriek)"}>
            {"Eie Koerier (Hartenbos Fabriek)"}
          </MenuItem>
          <MenuItem key={3} value={"Optel - Hartenbos (Winkel)"}>
            {"Optel (Hartenbos Winkel)"}
          </MenuItem>
        </Select>
       
        </FormControl>
                    </Box>
                    <Box display="flex">
                      <Box
                        flex={"1 1 auto"}
                        component={TextField}
                        label="Koepon / Geskenkbewys Kode"
                        variant="outlined"
                        color="primary"
                        fullWidth
                        height={54}
                        maxWidth={300}
                        onChange={(e) => setCouponCode(e.target.value)}
                        value={couponCode}
                      />
                      <Box
                        component={LoadingButton}
                        variant="contained"
                        color="primary"
                        size="large"
                        height={54}
                        marginLeft={1}
                        width={1}
                        flex={1}
                        minWidth={100}
                        onClick={addCoupon}
                        loading={loadingCoupon}
                      >
                        Gebruik
                      </Box>
                    </Box>
                  </Box>
                  <Stack spacing={2} marginY={{ xs: 2, sm: 4 }}>
                    <Box display={"flex"} justifyContent={"space-between"}>
                      <Typography color={"text.secondary"}>
                        Subtotaal
                      </Typography>
                      <Typography color={"text.secondary"} fontWeight={700}>
                        {currencyFormat(basket.amountSubTotal)}
                      </Typography>
                    </Box>

                    <Box display={"flex"} justifyContent={"space-between"}>
                      <Typography color={"text.secondary"}>Afslag</Typography>
                      <Typography color={"text.secondary"} fontWeight={700}>
                        -{currencyFormat(basket.amountDiscount)}
                      </Typography>
                    </Box>
                    <Box display={"flex"} justifyContent={"space-between"}>

                      <Typography component={"span"} color={"text.secondary"}>
                        Koepon / Geskenkbewys
                        <br />
                        {basket?.coupons?.length > 0 && (
                          <small>
                          <ul>
                            {basket.coupons.map((c) => (
                              <li key={c.id}>
                              {c.title}    &nbsp; 
                                <Box
                                  component={"svg"}
                                  xmlns="http://www.w3.org/2000/svg"
                                  width={15}
                                  height={15}
                                  fill="none"
                                  viewBox="0 0 24 24"
                                  stroke="currentColor"
                                  marginRight={0.5}
                                  color="error"
                                  onClick={() => removeCoupon(c.id)}
                                >
                                  <path
                                    strokeLinecap="round"
                                    strokeLinejoin="round"
                                    strokeWidth={2}
                                    d="M19 7l-.867 12.142A2 2 0 0116.138 21H7.862a2 2 0 01-1.995-1.858L5 7m5 4v6m4-6v6m1-10V4a1 1 0 00-1-1h-4a1 1 0 00-1 1v3M4 7h16"
                                  />
                                </Box> 
                               
                              </li>
                            ))}
                          </ul>
                          </small>
                        )}
                      </Typography>
                      <Typography color={"text.secondary"} fontWeight={700}>
                        -{currencyFormat(basket.amountCouponDiscount)}
                      </Typography>
                    </Box>

                    <Box display={"flex"} justifyContent={"space-between"}>
                      <Typography color={"text.secondary"}>
                        {basket.deliveryOption === "Aflewering"? 'Aflewering ' : 'Optel / Eie Koerier '}
                        ({(basket.totalWeight / 1000).toFixed(2)}kg)

                       
                      </Typography>
                      <Typography color={"text.secondary"} fontWeight={700}>
                        {currencyFormat(basket.amountDelivery)}
                      </Typography>
                    </Box>

                    <Box display={"flex"} justifyContent={"space-between"}>
                      <Typography color={"text.secondary"}>
                        BTW Ingesluit (15%)
                      </Typography>
                      <Typography color={"text.secondary"} fontWeight={700}>
                        {currencyFormat(basket.amountTax)}
                      </Typography>
                    </Box>

                    <Divider />
                    <Box display={"flex"} justifyContent={"space-between"}>
                      <Typography variant={"h6"} fontWeight={700}>
                        Totaal <small>(Insl. 15% BTW)</small>
                      </Typography>
                      <Typography variant={"h6"} fontWeight={700}>
                        {currencyFormat(basket.amountTotal)}
                      </Typography>
                    </Box>

                  </Stack>
                </Box>
              </Card>
          
  );
};

export default CartSummary;
