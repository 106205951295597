import Alert from "@mui/material/Alert";
import { Box } from "@mui/material";

export default function FactoryClosedNotice() {
  const date = new Date();

  if (date < new Date("10 January 2024") && date > new Date("11 December 2023")) {
    return (
      <Box padding={3}>
        <Alert variant="outlined" icon={false} severity="success">
          Neem asb kennis ons fabriek is gesluit en nuwe bestellings sal vanaf
          10 Januarie 2024 verwerk word.
        </Alert>
      </Box>
    );
  }

  return null;
}
